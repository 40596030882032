<template>
  <base-layout>
    <ion-content class="has-header has-subheader">
      <ion-list>
        <div id="map-wrapper">
          <router-link :to="'/app/maps/' + map.id" class="map-item-wrapper" v-for="map in maps" :key="map.id">
            <div class="map-item" :style="{'background-image': 'url(' + map.map_file + ')'}">
              <div class="map-item-title">{{ map.title }}</div>
            </div>
          </router-link>
        </div>
      </ion-list>
    </ion-content>
  </base-layout>
</template>
<script>
import {IonContent} from "@ionic/vue";
import {defineComponent} from "vue";
import {mapActions} from "vuex";


export default defineComponent({
  name: "MapDetail",
  data() {
    return {
      maps: []
    };
  },
  components: {
    IonContent,

  },
  methods: {
    ...mapActions("maps", ["getMaps"])
  },

  async created() {
    this.maps = await this.getMaps();
    console.log("mapaasdasdas")
    console.log(this.maps)
    this.maps = this.maps.sort((a, b) => {
      return (a.order)-(b.order);
    });
  }
});
</script>

<style lang="scss" scoped>
a {
  text-decoration: none
}
</style>